import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { StrategyInfoFields } from '@/components/blocks/StrategyInfoFields/StrategyInfoFields';
import { SymbolSearchSelect } from '@/components/blocks/SymbolSearchSelect/SymbolSearchSelect';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useDashboardContext } from '@/context/DashboardContext';
import { useStrategyActions } from '@/hooks/useStrategies';
import { StartStrategyParams, Strategy, StrategyModeEnum } from '@/types';

type StartStrategyModalProps = Partial<Strategy> & {
  onClose: () => void;
  onConfirm: (params: StartStrategyParams) => Promise<void>;
};

const formSchema = z
  .object({
    mode: z.nativeEnum(StrategyModeEnum),
    symbol: z.string(),
    minPrice: z.coerce.number().positive(),
    maxPrice: z.coerce.number().positive(),
    step: z.coerce.number().min(0.1).max(5),
    leverage: z.coerce.number().min(1).max(25),
    multiplier: z.coerce.number().min(1).max(3),
    capital: z.coerce.number().optional(),
    startPrice: z.coerce.number().optional(),
    hedgeEnabled: z.boolean(),
    hedgeGap: z.coerce.number().min(-30).max(30).optional(),
  })
  .refine(schema => schema.minPrice < schema.maxPrice, {
    message: 'Min price should be less than max price',
    path: ['minPrice'],
  })
  .refine(schema => !schema.hedgeEnabled || (schema.hedgeEnabled && schema.hedgeGap != undefined), {
    message: 'Hedge gap should be set if hedge is enabled',
    path: ['hedgeGap'],
  });

export const StartStrategyModal = ({
  onClose,
  onConfirm,
  ...defaultParams
}: StartStrategyModalProps) => {
  const { calculateStrategyMutation } = useStrategyActions();
  const { activeSymbol } = useDashboardContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [validStrategyParams, setValidStrategyParams] = useState<StartStrategyParams>();
  const [calculatedParams, setCalculatedParams] = useState<any>();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: async (data, context, options) => {
      setValidStrategyParams(undefined);
      return zodResolver(formSchema)(data, context, options);
    },

    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      mode: defaultParams.mode || StrategyModeEnum.normal,
      symbol: defaultParams.symbol || activeSymbol,
      minPrice: defaultParams.minPrice || 0,
      maxPrice: defaultParams.maxPrice || 0,
      step: (defaultParams.step || 0.0128) * 100,
      leverage: defaultParams.leverage || 10,
      multiplier: defaultParams.multiplier || 2,
      capital: defaultParams.capital || undefined,
      startPrice: defaultParams.strategyStartPrice || undefined,
      hedgeEnabled: defaultParams.hedgeEnabled || false,
      hedgeGap: defaultParams.hedgeGap || undefined,
    },
  });

  const handleCalculate = useCallback(
    (strategyParams: z.infer<typeof formSchema>) => {
      setIsLoading(true);
      setError('');
      calculateStrategyMutation
        .mutateAsync(strategyParams)
        .then(res => {
          setValidStrategyParams(strategyParams);
          setCalculatedParams(res.data.params);
        })
        .catch(err => {
          setError(err.response.data.detail);
        })
        .finally(() => setIsLoading(false));
    },
    [calculateStrategyMutation]
  );

  const handleStart = useCallback(() => {
    if (validStrategyParams) {
      setIsLoading(true);
      onConfirm(validStrategyParams)
        .catch(err => {
          setError(err.response.data.detail);
        })
        .finally(() => setIsLoading(false));
    }
  }, [validStrategyParams, onConfirm]);

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent
        aria-describedby="Start strategy dialog"
        className="flex max-h-[90vh] w-fit max-w-fit flex-col"
      >
        <DialogHeader>
          <DialogTitle>Start strategy</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <div className="flex gap-8">
          {isLoading ? <CenteredSpinner /> : null}

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleCalculate)}
              className="flex w-[360px] flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="mode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Strategy mode</FormLabel>
                    <FormControl>
                      <Select value={field.value} name={field.name} onValueChange={field.onChange}>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={field.value}
                            onBlur={field.onBlur}
                            ref={field.ref}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(StrategyModeEnum).map(([key, value]) => (
                            <SelectItem key={key} value={value}>
                              {value.replaceAll('_', ' ')}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex items-start gap-3">
                <FormField
                  control={form.control}
                  name="symbol"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Symbol</FormLabel>
                      <FormControl>
                        <SymbolSearchSelect
                          className="w-full"
                          symbol={field.value}
                          variant="outline"
                          onSetSymbol={val => field.onChange(val)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="step"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Step</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex items-start gap-3">
                <FormField
                  control={form.control}
                  name="minPrice"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Min price</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="maxPrice"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Max price</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex items-start gap-3">
                <FormField
                  control={form.control}
                  name="leverage"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Leverage</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="multiplier"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Capital multiplier</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex items-start gap-3">
                <FormField
                  control={form.control}
                  name="capital"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Capital, USD</FormLabel>
                      <FormControl>
                        <Input {...field} value={field.value || ''} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="startPrice"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Start price</FormLabel>
                      <FormControl>
                        <Input {...field} value={field.value || ''} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex items-start gap-3">
                <FormField
                  control={form.control}
                  name="hedgeEnabled"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormControl>
                        <Checkbox
                          ref={field.ref}
                          id="strategyHedgeEnabled"
                          label="Enable hedge order"
                          checked={field.value}
                          disabled={field.disabled}
                          onBlur={field.onBlur}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="hedgeGap"
                  render={({ field, formState }) =>
                    formState.dirtyFields.hedgeEnabled ? (
                      <FormItem className="basis-1/2">
                        <FormLabel>Hedge gap, %</FormLabel>
                        <FormControl>
                          <Input {...field} value={field.value ?? ''} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    ) : null
                  }
                />
              </div>
              {form.getValues().hedgeEnabled ? (
                <div className="text-xs text-secondary-foreground">
                  Specify gap under min price at which hedge should be placed.
                  <br />
                  STOP_MARKET order with max strategy position will be placed. <br />
                  5% - means 5% under min price
                  <br />
                  -5% - means 5% over min price
                </div>
              ) : null}

              <FormMessage>{error}</FormMessage>

              <div className="mt-auto flex justify-between">
                <Button variant="destructive" onClick={onClose}>
                  Close
                </Button>

                <Button disabled={Boolean(validStrategyParams)} type="submit">
                  Calculate
                </Button>
              </div>
            </form>
          </Form>

          {Boolean(calculatedParams) ? (
            <div className="flex w-[360px] flex-col overflow-auto">
              <StrategyInfoFields {...calculatedParams} />

              <Button className="mt-4" disabled={!validStrategyParams} onClick={handleStart}>
                Start strategy
              </Button>
            </div>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
};
