import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '@/context/AuthContext';
import {
  calculateStrategy,
  getActiveStrategies,
  getStoppedStrategies,
  getStrategyById,
  getTerminatedStrategies,
  restartStrategies,
  startStrategy,
  stopStrategy,
  terminateAllStrategies,
  updateStrategyHedge,
} from '@/modules/api';
import {
  StartStrategyParams,
  Strategy,
  StrategyCalculatedParams,
  StrategyStopModeEnum,
} from '@/types';

import { useNotification } from './useNotification';
import { OPEN_ORDERS_REQUEST } from './useOrders';
import { STATUS_REQUEST } from './useStatistic';

export const STRATEGY_REQUEST_UPDATE_DELAY = 8_000;

export const STRATEGY_INFO_REQUEST = 'strategy-info-request';
export const useStrategy = (strategyId: number) => {
  const { isAuth } = useAuth();

  const strategyInfoRequest = useQuery({
    queryKey: [STRATEGY_INFO_REQUEST, strategyId],
    queryFn: async () => await getStrategyById(strategyId),
    select: data => data.data,
    enabled: isAuth && Boolean(strategyId),
  });

  const strategyStartParams = useMemo(
    () => strategyInfoRequest.data?.startParams as StrategyCalculatedParams,
    [strategyInfoRequest.data]
  );
  const strategyWorkingStat = useMemo(
    () => strategyInfoRequest.data?.strategy as Strategy,
    [strategyInfoRequest.data]
  );

  return { strategyInfoRequest, strategyStartParams, strategyWorkingStat };
};

export const ACTIVE_STRATEGIES_REQUEST = 'active-strategies-request';
export const useActiveStrategies = () => {
  const { isAuth } = useAuth();

  const activeStrategiesRequest = useQuery({
    queryKey: [ACTIVE_STRATEGIES_REQUEST],
    queryFn: async () => await getActiveStrategies(),
    select: data => data.data.activeStrategies as Strategy[],
    enabled: isAuth,
    staleTime: Infinity,
  });

  const activeStrategies = useMemo(
    () => activeStrategiesRequest.data || [],
    [activeStrategiesRequest.data]
  );

  return { activeStrategies, activeStrategiesRequest };
};

export const TERMINATED_STRATEGIES_REQUEST = 'terminated-strategies-request';
export const useTerminatedStrategies = () => {
  const { isAuth } = useAuth();

  const terminatedStrategiesRequest = useQuery({
    queryKey: [TERMINATED_STRATEGIES_REQUEST],
    queryFn: async () => await getTerminatedStrategies(),
    select: data => data.data.terminatedStrategies as Strategy[],
    enabled: isAuth,
    staleTime: Infinity,
  });

  const terminatedStrategies = useMemo(
    () => terminatedStrategiesRequest.data || [],
    [terminatedStrategiesRequest.data]
  );

  return { terminatedStrategies, terminatedStrategiesRequest };
};

export const STOPPED_STRATEGIES_REQUEST = 'stopped-strategies-request';
export const useStoppedStrategies = () => {
  const { isAuth } = useAuth();

  const stoppedStrategiesRequest = useQuery({
    queryKey: [STOPPED_STRATEGIES_REQUEST],
    queryFn: async () => await getStoppedStrategies(),
    select: data => data.data.stoppedStrategies as Strategy[],
    enabled: isAuth,
    staleTime: Infinity,
  });

  const stoppedStrategies = useMemo(
    () => stoppedStrategiesRequest.data || [],
    [stoppedStrategiesRequest.data]
  );

  return {
    stoppedStrategiesRequest,
    stoppedStrategies,
  };
};

export const useAllStrategies = () => {
  const { activeStrategies, activeStrategiesRequest } = useActiveStrategies();
  const { terminatedStrategies, terminatedStrategiesRequest } = useTerminatedStrategies();
  const { stoppedStrategies, stoppedStrategiesRequest } = useStoppedStrategies();

  const allStrategies = useMemo(
    () => [...activeStrategies, ...terminatedStrategies, ...stoppedStrategies],
    [activeStrategies, terminatedStrategies, stoppedStrategies]
  );

  return {
    activeStrategies,
    terminatedStrategies,
    stoppedStrategies,
    allStrategies,
    isLoading:
      activeStrategiesRequest.isLoading ||
      terminatedStrategiesRequest.isLoading ||
      stoppedStrategiesRequest.isLoading,
  };
};

const START_STRATEGY_MUTATION = 'start-strategy-mutation';
const CALCULATE_STRATEGY_MUTATION = 'calculate-strategy-mutation';
const STOP_STRATEGY_MUTATION = 'stop-strategy-mutation';
const UPDATE_STRATEGY_HEDGE_MUTATION = 'update-strategy-hedge-mutation';
const RESTART_STRATEGIES_MUTATION = 'restart-strategy-mutation';
export const useStrategyActions = () => {
  const { handleError, info } = useNotification();
  const { isAuth } = useAuth();

  const queryClient = useQueryClient();

  const restartStrategiesMutation = useMutation({
    mutationKey: [RESTART_STRATEGIES_MUTATION],
    mutationFn: async (ids: number[]) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await restartStrategies(ids);
      info({ title: `Strategy ${ids} restarting...` });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STATUS_REQUEST] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  const startStrategyMutation = useMutation({
    mutationKey: [START_STRATEGY_MUTATION],
    mutationFn: async (params: StartStrategyParams) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await startStrategy(params);
      info({ title: `Starting strategy...` });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STATUS_REQUEST] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  const stopStrategyMutation = useMutation({
    mutationKey: [STOP_STRATEGY_MUTATION],
    mutationFn: async ({ id, stopMode }: { id: number; stopMode: StrategyStopModeEnum }) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await stopStrategy(id, stopMode);
      info({ title: `Strategy ${id} stopping...` });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STOPPED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STATUS_REQUEST] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  const updateStrategyHedgeMutation = useMutation({
    mutationKey: [UPDATE_STRATEGY_HEDGE_MUTATION],
    mutationFn: async ({
      id,
      hedgeEnabled,
      hedgeGap,
    }: {
      id: number;
      hedgeEnabled: boolean;
      hedgeGap?: number;
    }) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await updateStrategyHedge(id, hedgeEnabled, hedgeGap ? hedgeGap / 100 : hedgeGap);
      info({
        title: `Hedge for strategy (${id}) ${hedgeEnabled ? 'enabled with gap ' + hedgeGap + '%' : 'disabled'}`,
      });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STOPPED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  const calculateStrategyMutation = useMutation({
    mutationKey: [CALCULATE_STRATEGY_MUTATION],
    mutationFn: async (params: StartStrategyParams) => calculateStrategy(params),
    onError: handleError,
  });

  return {
    restartStrategiesMutation,
    startStrategyMutation,
    calculateStrategyMutation,
    stopStrategyMutation,
    updateStrategyHedgeMutation,
  };
};

const TERMINATE_ALL_STRATEGIES_MUTATION = 'terminate-all-strategies-mutation';
export const useTerminateAllStrategies = () => {
  const { handleError, info } = useNotification();
  const { isAuth } = useAuth();
  const queryClient = useQueryClient();

  const terminateAllMutation = useMutation({
    mutationKey: [TERMINATE_ALL_STRATEGIES_MUTATION],
    mutationFn: async () => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await terminateAllStrategies();
      info({ title: `Strategies terminating...` });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STOPPED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  return terminateAllMutation;
};
