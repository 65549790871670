import { useMemo } from 'react';

import {
  StrategyInfoFields,
  StrategyInfoFieldValue,
} from '@/components/blocks/StrategyInfoFields/StrategyInfoFields';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useStrategy } from '@/hooks/useStrategies';
import { formatDate, formatNumber, getAliveDays, getNowTimestamp } from '@/lib/common';

type StrategyInfoModalProps = { strategyId: number; onClose: () => void };
export const StrategyInfoModal = ({ strategyId, onClose }: StrategyInfoModalProps) => {
  const {
    strategyStartParams,
    strategyWorkingStat,
    strategyInfoRequest: { isLoading },
  } = useStrategy(strategyId);

  const status = useMemo(() => {
    if (!strategyWorkingStat) return null;
    if (strategyWorkingStat.isActive && !strategyWorkingStat.isTerminated) return '✅ active';
    if (strategyWorkingStat.isTerminated) return '⏸️ terminated';
    return '⏹️ stopped';
  }, [strategyWorkingStat]);

  const alive = useMemo(
    () =>
      strategyWorkingStat
        ? getAliveDays(
            strategyWorkingStat.startTimestamp,
            strategyWorkingStat.stopTimestamp || getNowTimestamp()
          )
        : '---',
    [strategyWorkingStat]
  );

  const averageProfit = useMemo(
    () =>
      strategyWorkingStat ? strategyWorkingStat.totalProfit / strategyWorkingStat.totalTrades : 0,
    [strategyWorkingStat]
  );

  const averageFee = useMemo(
    () =>
      strategyWorkingStat ? strategyWorkingStat.totalFees / strategyWorkingStat.totalTrades : 0,
    [strategyWorkingStat]
  );

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent
        aria-describedby="Strategy info dialog"
        className="flex max-h-[90vh] min-h-[400px] w-fit max-w-fit flex-col"
      >
        <DialogHeader>
          <DialogTitle>Strategy info</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        {isLoading ? <CenteredSpinner /> : null}

        <div className="flex gap-4">
          <div className="flex w-[300px] flex-col gap-1">
            <p className="text-subheading text-center">Working params</p>
            {strategyWorkingStat ? (
              <>
                <StrategyInfoFieldValue label="Strategy id:">
                  {strategyWorkingStat.id}
                </StrategyInfoFieldValue>

                <StrategyInfoFieldValue label="Status:">{status}</StrategyInfoFieldValue>

                <StrategyInfoFieldValue label="Current level:">
                  {strategyWorkingStat.gridLevel} (
                  {strategyStartParams
                    ? strategyStartParams.levels[strategyWorkingStat.gridLevel]
                    : '---'}
                  $)
                </StrategyInfoFieldValue>

                <StrategyInfoFieldValue label="Started at:">
                  {formatDate(new Date(strategyWorkingStat.startTimestamp), { withYear: true })}
                </StrategyInfoFieldValue>

                <StrategyInfoFieldValue label="Alive:">{alive} days</StrategyInfoFieldValue>

                {strategyWorkingStat.stopTimestamp ? (
                  <StrategyInfoFieldValue label="Stopped at:">
                    {formatDate(new Date(strategyWorkingStat.stopTimestamp), { withYear: true })}
                  </StrategyInfoFieldValue>
                ) : null}

                <p className="text-subheading">STATISTIC</p>

                <StrategyInfoFieldValue label="Trade balance:">
                  {formatNumber(strategyWorkingStat.tradeBalance, 2)} $
                </StrategyInfoFieldValue>

                <StrategyInfoFieldValue label="Operation trades:">
                  {strategyWorkingStat.totalTrades}
                </StrategyInfoFieldValue>

                <StrategyInfoFieldValue label="Operation profit:">
                  {formatNumber(strategyWorkingStat.totalProfit, 2)} $
                </StrategyInfoFieldValue>

                <StrategyInfoFieldValue label="Avg. trade profit:">
                  {formatNumber(averageProfit, 2)} $
                </StrategyInfoFieldValue>

                <StrategyInfoFieldValue label="Total fees:">
                  {formatNumber(strategyWorkingStat.totalFees, 2)} $
                </StrategyInfoFieldValue>

                <StrategyInfoFieldValue label="Avg. trade fee:">
                  {formatNumber(averageFee, 2)} $
                </StrategyInfoFieldValue>

                <StrategyInfoFieldValue label="Total PNL:">
                  {formatNumber(strategyWorkingStat.totalPnl, 2)} $
                </StrategyInfoFieldValue>
              </>
            ) : null}
          </div>
          <div className="flex w-[350px] flex-col">
            <p className="text-subheading text-center">Creation params</p>
            {strategyStartParams ? <StrategyInfoFields {...strategyStartParams} /> : null}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
