import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useStrategy, useStrategyActions } from '@/hooks/useStrategies';

type UpdateStrategyHedgeProps = {
  strategyId: number;
  onClose: () => void;
};
export const UpdateStrategyHedge = ({ strategyId, onClose }: UpdateStrategyHedgeProps) => {
  const {
    strategyInfoRequest: { isLoading },
    strategyWorkingStat,
  } = useStrategy(strategyId);
  const { updateStrategyHedgeMutation } = useStrategyActions();

  const [isHedgeEnabled, setIsHedgeEnabled] = useState(false);
  const [hedgeGap, setHedgeGap] = useState<number>();

  const isValid = useMemo(
    () =>
      !isHedgeEnabled ||
      (isHedgeEnabled && (hedgeGap || hedgeGap === 0) && hedgeGap >= -30 && hedgeGap <= 30),
    [isHedgeEnabled, hedgeGap]
  );

  useEffect(() => {
    if (strategyWorkingStat) {
      setIsHedgeEnabled(strategyWorkingStat.hedgeEnabled || false);
      setHedgeGap(
        strategyWorkingStat.hedgeGap
          ? strategyWorkingStat.hedgeGap * 100
          : strategyWorkingStat.hedgeGap
      );
    }
  }, [strategyWorkingStat]);

  const handleSubmit = useCallback(() => {
    if (!isValid) return false;

    updateStrategyHedgeMutation
      .mutateAsync({
        id: strategyId,
        hedgeEnabled: isHedgeEnabled,
        hedgeGap,
      })
      .then(() => onClose());
  }, [onClose, strategyId, updateStrategyHedgeMutation, isHedgeEnabled, hedgeGap, isValid]);

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Update strategy hedge</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        {isLoading ? <CenteredSpinner /> : null}

        <div className="mt-4 flex flex-col gap-2">
          <Checkbox
            id="strategyHedgeEnabled"
            label="Enable hedge order for this strategy"
            checked={isHedgeEnabled}
            disabled={isLoading}
            onCheckedChange={val => setIsHedgeEnabled(Boolean(val))}
          />

          <p className="mt-4">
            Hedge gap, % <span className="text-third-foreground">(from -30% to 30%)</span>
          </p>
          <Input
            type="number"
            min={-30}
            max={30}
            value={hedgeGap ?? ''}
            disabled={isLoading || !isHedgeEnabled}
            onChange={e => setHedgeGap(parseFloat(e.target.value))}
          />

          <div className="mt-4 flex justify-between">
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            <Button
              isLoading={updateStrategyHedgeMutation.isPending}
              disabled={!isValid}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
