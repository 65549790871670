import axios from 'axios';

import { eraseCookie } from '@/lib/common';
import { convertKeysToCamelCase } from '@/lib/utils';
import {
  KlineIntervalEnum,
  OrderCreateParams,
  StartStrategyParams,
  StrategyCalculatedParams,
  StrategyStopModeEnum,
} from '@/types';
const baseApiUrl = import.meta.env.VITE_BASE_API_URL || '';

const instance = axios.create({
  baseURL: baseApiUrl,
  //   timeout: 1000,
  headers: {},
  withCredentials: true, // Включаем отправку куков с запросами
});

export const IS_AUTHORIZED_KEY = 'auth';

// Переменные для хранения состояния refresh token запроса
let isRefreshing = false;
let failedQueue: { resolve: any; reject: any }[] = [];

const processQueue = (isError: boolean) => {
  failedQueue.forEach(prom => {
    if (isError) prom.reject();
    else prom.resolve();
  });

  failedQueue = [];
};

// instance.interceptors.request.use(
//   request => ({ ...request, data: convertKeysToUnderscore(request.data) }),
//   undefined
// );

instance.interceptors.response.use(
  response => ({ ...response, data: convertKeysToCamelCase(response.data) }),
  async (error: any) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes('auth')
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => instance(originalRequest))
          .catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        refresh()
          .then(() => {
            processQueue(false);
            resolve(instance(originalRequest));
          })
          .catch(err => {
            processQueue(true);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    if (originalRequest.url.includes('/refresh') || originalRequest.url.includes('/logout')) {
      eraseCookie(IS_AUTHORIZED_KEY);
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

// --- Routes
const login = (login: string, password: string) => {
  return instance.post('/api/v1/auth/login', { login, password });
};
const refresh = () => {
  return instance.post('/api/v1/auth/refresh');
};
const logout = () => {
  return instance.post('/api/v1/auth/logout');
};

// ---- strategies ----
const getStoppedStrategies = () => {
  return instance.get('/api/v1/strategies/stopped');
};

const getTerminatedStrategies = () => {
  return instance.get('/api/v1/strategies/terminated');
};

const getActiveStrategies = () => {
  return instance.get('/api/v1/strategies/active');
};

const getStrategyById = (id: number) => {
  return instance.get(`/api/v1/strategies/${id}`);
};

const startStrategy = ({
  minPrice,
  maxPrice,
  startPrice,
  hedgeEnabled,
  hedgeGap,
  ...params
}: StartStrategyParams) => {
  return instance.post('/api/v1/strategies/grid/start', {
    ...params,
    min_price: minPrice,
    max_price: maxPrice,
    start_price: startPrice,
    hedge_enabled: hedgeEnabled,
    hedge_gap: hedgeGap,
  });
};
const calculateStrategy = ({
  minPrice,
  maxPrice,
  startPrice,
  hedgeEnabled,
  hedgeGap,
  ...params
}: StartStrategyParams) => {
  return instance.post<{ params: StrategyCalculatedParams }>('/api/v1/strategies/grid/calculate', {
    ...params,
    min_price: minPrice,
    max_price: maxPrice,
    start_price: startPrice,
    hedge_enabled: hedgeEnabled,
    hedge_gap: hedgeGap,
  });
};
const updateStrategyHedge = (strategyId: number, hedgeEnabled: boolean, hedgeGap?: number) => {
  return instance.patch('/api/v1/strategies/grid/hedge', {
    strategy_id: strategyId,
    hedge_enabled: hedgeEnabled,
    hedge_gap: hedgeGap,
  });
};
const stopStrategy = (strategy_id: number, stopType: StrategyStopModeEnum) => {
  return instance.post('/api/v1/strategies/grid/stop', { strategy_id, stop_type: stopType });
};
const terminateAllStrategies = () => {
  return instance.post('/api/v1/strategies/terminate_all');
};
const restartStrategies = (ids: number[]) => {
  return instance.post(`/api/v1/strategies/terminated/restart`, { strategy_ids: ids });
};

// ---------- positions ----------
// /api/v1/positions
const getPositions = () => {
  return instance.get(`/api/v1/positions`);
};
// /api/v1/positions/balance
const getBalance = () => {
  return instance.get(`/api/v1/positions/balance`);
};
// /api/v1/positions/account
const getAccount = () => {
  return instance.get(`/api/v1/positions/account`);
};
const getAssetIndex = (symbol?: string) => {
  return instance.get(`/api/v1/positions/asset_index`, { params: { symbol } });
};
const getAdlQuantile = (symbol?: string) => {
  return instance.get(`/api/v1/positions/adl_quantile`, { params: { symbol } });
};
const getTransfers = () => {
  return instance.get(`/api/v1/positions/transfers`);
};
const setTransferEntryPrice = (transferId: number, entryPrice: number) => {
  return instance.post(`/api/v1/positions/transfers/entry_price`, {
    transfer_id: transferId,
    entry_price: entryPrice,
  });
};
const setLeverage = (symbol: string, leverage: number) => {
  return instance.post(`/api/v1/positions/leverage`, { symbol, leverage });
};
// /api/v1/positions/klines
const getKlines = (
  symbol: string,
  interval: KlineIntervalEnum,
  startTs?: number,
  endTs?: number,
  limit?: number
) => {
  return instance.get(`/api/v1/positions/klines`, {
    params: { symbol, interval, start_ts: startTs, end_ts: endTs, limit },
  });
};

// ---------- orders ----------
const getOpenOrders = (symbol?: string) => {
  return instance.get('/api/v1/orders/open', { params: { symbol } });
};
const getTrades = (fromTs: number, toTs?: number) => {
  return instance.get('/api/v1/orders/trades', { params: { from_ts: fromTs, to_ts: toTs } });
};
const cancelOrders = (symbol: string, clientOrderIds: string[]) => {
  return instance.post('/api/v1/orders/cancel', { client_order_ids: clientOrderIds, symbol });
};
const calculateOrder = (
  { stopPrice, workingType, timeInForce, positionSide, ...params }: OrderCreateParams,
  spentUsdt?: number
) => {
  return instance.post(`/api/v1/orders/calculate`, {
    ...params,
    stop_price: stopPrice,
    working_type: workingType,
    time_in_force: timeInForce,
    position_side: positionSide,
    spent_usdt: spentUsdt,
  });
};
const placeOrder = ({
  stopPrice,
  workingType,
  timeInForce,
  positionSide,
  ...params
}: OrderCreateParams) => {
  return instance.post(`/api/v1/orders/place`, {
    ...params,
    stop_price: stopPrice,
    working_type: workingType,
    time_in_force: timeInForce,
    position_side: positionSide,
  });
};

// /api/v1/status
const getStatus = () => {
  return instance.get('/api/v1/status');
};
// /api/v1/status/report
const getReport = (fromTs: number, toTs?: number) => {
  return instance.get('/api/v1/status/report', { params: { from_ts: fromTs, to_ts: toTs } });
};
const getStartTime = () => {
  return instance.get('/api/v1/status/start_time');
};
const getTradeBalances = (fromTs: number, toTs?: number) => {
  return instance.get('/api/v1/status/trade_balances', {
    params: { from_ts: fromTs, to_ts: toTs },
  });
};
// ------ settings -------
const stopSystem = () => {
  return instance.post('/api/v1/settings/stop_system');
};

// /api/v1/export_trade_bot_log
const getTradeBotLogs = (rows: number = 1_000) => {
  return instance.get('/api/v1/settings/export_trade_bot_log', { params: { rows } });
};
// /api/v1/upload_logs_to_s3
const exportLogsToS3 = () => {
  return instance.post('/api/v1/settings/upload_logs_to_s3');
};

export {
  calculateOrder,
  calculateStrategy,
  cancelOrders,
  exportLogsToS3,
  // positions
  getAccount,
  // strategies
  getActiveStrategies,
  getAdlQuantile,
  getAssetIndex,
  getBalance,
  getKlines,
  // orders
  getOpenOrders,
  getPositions,
  getReport,
  getStartTime,
  // statistic
  getStatus,
  // getAllStrategies,
  getStoppedStrategies,
  getStrategyById,
  getTerminatedStrategies,
  getTradeBalances,
  getTradeBotLogs,
  getTrades,
  getTransfers,
  instance,
  // authentication
  login,
  logout,
  placeOrder,
  refresh,
  restartStrategies,
  setLeverage,
  setTransferEntryPrice,
  startStrategy,
  stopStrategy,
  // system
  stopSystem,
  terminateAllStrategies,
  updateStrategyHedge,
};
