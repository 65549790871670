import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  ColorType,
  createChart,
  CrosshairMode,
  IChartApi,
  IPriceLine,
  ISeriesApi,
  LineStyle,
} from 'lightweight-charts';

import { useThemeContext } from '@/context/ThemeContext';
import { formatDate, isReduceOrder } from '@/lib/common';
import { Order, OrderTypeEnum } from '@/types';

import { defaultChartColors, defaultDarkChartColors, KlineData } from './commonChartProps';

const defaultGridLineProps = {
  color: '#3b82f6',
  lineStyle: LineStyle.Solid,
  axisLabelVisible: true,
};
const defaultOrderLineProps = {
  // color: 'green' | 'red' \ 'orange',
  lineStyle: LineStyle.Solid,
  axisLabelVisible: true,
};

const COLOR_GREEN = '#0ecb81';
const COLOR_RED = '#f6465d';

const defaultCandlestickProps = {
  upColor: COLOR_GREEN,
  downColor: COLOR_RED,
  borderVisible: false,
  wickUpColor: COLOR_GREEN,
  wickDownColor: COLOR_RED,
};
const ALGO_ORDER_COLOR = '#ffc20c';
const REDUCE_ORDER_COLOR = COLOR_RED;
const BUY_ORDER_COLOR = COLOR_GREEN;

export interface PriceChartComponentHandle {
  scaleFit: () => void;
  showGrid: (grid?: number[]) => void;
  showOrders: (orders?: Order[]) => void;
}

type PriceChartComponentProps = {
  data: KlineData[];
  colors?: Record<string, string>;
  grid?: number[];
  orders?: Order[];
};
export const PriceChart = forwardRef<PriceChartComponentHandle, PriceChartComponentProps>(
  ({ colors, data, grid, orders }, ref) => {
    const { isDark } = useThemeContext();
    const chartColors = { ...(isDark ? defaultDarkChartColors : defaultChartColors), ...colors };

    const {
      crosshairLabelColor,
      backgroundColor,
      lineColor,
      textColor,
      areaTopColor,
      areaBottomColor,
    } = chartColors;

    const [gridPriceLines, setGridPriceLines] = useState<IPriceLine[]>();
    const [orderPriceLines, setOrderPriceLines] = useState<IPriceLine[]>();

    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<IChartApi>();
    const seriesRef = useRef<ISeriesApi<any>>();

    useEffect(() => {
      if (chartContainerRef.current) {
        chartRef.current = createChart(chartContainerRef.current, {
          localization: {
            timeFormatter: (time: any) => formatDate(new Date(time * 1000), { withYear: true }),
          },
          layout: {
            background: { type: ColorType.Solid, color: backgroundColor },
            textColor,
            // attributionLogo: false,
          },
          grid: {
            vertLines: { color: lineColor },
            horzLines: { color: lineColor },
          },
          crosshair: {
            mode: CrosshairMode.Normal,
            vertLine: { labelBackgroundColor: crosshairLabelColor },
            horzLine: { labelBackgroundColor: crosshairLabelColor },
          },
          autoSize: true,
        });
      }

      return () => {
        chartRef.current?.remove();
        seriesRef.current = undefined;
      };
    }, [
      backgroundColor,
      crosshairLabelColor,
      lineColor,
      textColor,
      areaTopColor,
      areaBottomColor,
      isDark,
    ]);

    const createSeries = useCallback((dataset: KlineData[]) => {
      if (chartRef.current) {
        const precision = dataset.length && dataset[0].open < 1 ? 4 : 2;
        const minMove = precision == 2 ? 0.01 : 0.0001;
        seriesRef.current = chartRef.current.addCandlestickSeries({
          ...defaultCandlestickProps,
          priceFormat: {
            precision,
            minMove,
          },
        });
        seriesRef.current.setData(dataset);
        setGridPriceLines(undefined);
        setOrderPriceLines(undefined);
      }
    }, []);

    const showGridLines = useCallback(
      (lines?: number[]) => {
        if (gridPriceLines?.length) {
          gridPriceLines.map(pl => seriesRef.current?.removePriceLine(pl));
          setGridPriceLines(undefined);
        }

        if (seriesRef.current && lines) {
          const gridLines = lines.map((price, index) =>
            seriesRef.current!.createPriceLine({
              price: price,
              title: `${index}`,
              ...defaultGridLineProps,
            })
          );

          setGridPriceLines(gridLines);
        }
      },
      [gridPriceLines]
    );
    const showOrderLines = useCallback(
      (orders?: Order[]) => {
        if (orderPriceLines?.length) {
          orderPriceLines.map(pl => seriesRef.current?.removePriceLine(pl));
          setOrderPriceLines(undefined);
        }

        if (seriesRef.current && orders) {
          const orderLines = orders.map(order => {
            const isReduce = isReduceOrder(order);
            const isAlgo = ![OrderTypeEnum.LIMIT, OrderTypeEnum.MARKET].includes(order.type);
            const color = isAlgo
              ? ALGO_ORDER_COLOR
              : isReduce
                ? REDUCE_ORDER_COLOR
                : BUY_ORDER_COLOR;
            const price = isAlgo ? order.stopPrice : order.price;

            return seriesRef.current!.createPriceLine({
              price,
              color,
              title: isAlgo ? `${order.type} ${order.positionSide}` : `${order.positionSide}`,
              ...defaultOrderLineProps,
            });
          });

          setOrderPriceLines(orderLines);
        }
      },
      [orderPriceLines]
    );

    const scaleFit = useCallback(() => {
      if (seriesRef.current && chartRef.current) {
        chartRef.current.removeSeries(seriesRef.current);
        createSeries(data);
        if (grid) {
          showGridLines(grid);
        }
        if (orders) {
          showOrderLines(orders);
        }

        const currentData = seriesRef.current.data();
        const currentFromData =
          currentData.length > 100 ? currentData[currentData.length - 100] : currentData[0];
        const currentToData = currentData[currentData.length - 1];

        if (currentFromData && currentToData) {
          chartRef.current.timeScale().setVisibleRange({
            from: currentFromData.time,
            to: currentToData.time,
          });
        }
      }
      chartRef.current?.timeScale().scrollToRealTime();
    }, [data, grid, orders, createSeries, showGridLines, showOrderLines]);

    useEffect(() => {
      if (seriesRef.current) {
        const currentData = seriesRef.current?.data();
        const currentLastData = currentData[currentData.length - 1];
        const lastData = data[data.length - 1];
        const prevData = data[data.length - 2];
        const isSameDataset =
          lastData &&
          prevData &&
          currentLastData &&
          ((lastData.time === currentLastData.time && lastData.open === currentLastData.open) ||
            (prevData.time === currentLastData.time && prevData.open === currentLastData.open));

        if (isSameDataset) {
          seriesRef.current.update(lastData);
        } else {
          seriesRef.current.setData(data);
          scaleFit();
        }
      } else if (chartRef.current) {
        createSeries(data);
        scaleFit();
      }
    }, [data, isDark, scaleFit, createSeries]);

    useImperativeHandle(
      ref,
      () => ({
        showGrid(lines?: number[]) {
          showGridLines(lines);
        },
        showOrders(orders?: Order[]) {
          showOrderLines(orders);
        },
        scaleFit() {
          scaleFit();
        },
      }),
      [scaleFit, showGridLines, showOrderLines]
    );

    return <div className="h-full" ref={chartContainerRef} />;
  }
);
PriceChart.displayName = 'PriceChart';
