import { ShieldAlert } from 'lucide-react';

import { formatNumber, round } from '@/lib/common';
import { StrategyCalculatedParams } from '@/types';

type StrategyInfoFieldsProps = StrategyCalculatedParams;
export const StrategyInfoFields = ({
  symbol,
  mode,
  minPrice,
  maxPrice,
  startPrice,
  currentPrice,
  hedgeEnabled,
  hedgeGap,
  step,
  levels,
  closestLevelToCurrentPrice,
  closestLevelToStartPrice,
  levelsForInvestment,
  priceForLongTrades,
  tradeSize,
  initialPositionSize,
  initialCapital,
  priceForInitialPosition,
  currentPosition,
  // startRemainingPosition,
  currentRemainingPosition,
  currentPositionForInvestment,
  maxPositionSize,
  totalMaxSpent,
  leverage,
  leveragedCapital,
  capitalUsage,
  securityCapital,
  multiplier,
}: StrategyInfoFieldsProps) => {
  return (
    <div>
      <p className="text-subheading">PARAMS</p>

      <StrategyInfoFieldValue label="Symbol:">{symbol}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Mode:">{mode}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Min price:">{formatNumber(minPrice)}$</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Max price:">{formatNumber(maxPrice)}$</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Start price:">
        {formatNumber(startPrice, 3)}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Current price:">
        {formatNumber(currentPrice, 3)}$
      </StrategyInfoFieldValue>

      <p className="text-subheading">HEDGE</p>

      <StrategyInfoFieldValue label="Hedge:">
        <div className="flex items-center">
          <ShieldAlert className="mr-1 size-4 text-yellow" />
          {hedgeEnabled ? 'enabled' : 'disabled'}
        </div>
      </StrategyInfoFieldValue>

      {hedgeEnabled ? (
        <StrategyInfoFieldValue label="Hedge gap:">{(hedgeGap || 0) * 100}%</StrategyInfoFieldValue>
      ) : null}

      <p className="text-subheading">LEVELS</p>

      <StrategyInfoFieldValue label="Step:">{round(step * 100, 2)}%</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Levels:">
        {levels.length} ({formatNumber(levels[0], 3)}$ -{' '}
        {formatNumber(levels[levels.length - 1], 3)}$)
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Start level:">
        {closestLevelToStartPrice} ({formatNumber(levels[closestLevelToStartPrice], 3)}
        $)
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Current level:">
        {closestLevelToCurrentPrice} ({formatNumber(levels[closestLevelToCurrentPrice], 3)}
        $)
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Investment levels:">
        {levelsForInvestment}
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Reserved for long:">
        {formatNumber(priceForLongTrades, 2)}$
      </StrategyInfoFieldValue>

      <p className="text-subheading">POSITION</p>

      <StrategyInfoFieldValue label="Trade size:">{tradeSize}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Initial position:">
        {initialPositionSize} ({formatNumber(priceForInitialPosition, 2)}$)
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Max position:">{maxPositionSize}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Current required:">
        {currentPositionForInvestment}
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Current position:">
        {currentPosition} ({formatNumber(currentPosition * currentPrice, 2)}
        $)
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Remaining position:">
        {currentRemainingPosition}
      </StrategyInfoFieldValue>

      <p className="text-subheading">CAPITAL</p>

      <StrategyInfoFieldValue label="Leverage:">{leverage}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Multiplier:">{multiplier}</StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Initial capital:">
        {formatNumber(initialCapital, 2)}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Leveraged capital:">
        {formatNumber(leveragedCapital, 2)}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Security capital:">
        {formatNumber(securityCapital, 2)}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Total max spent:">
        {formatNumber(totalMaxSpent, 2)}$
      </StrategyInfoFieldValue>

      <StrategyInfoFieldValue label="Capital usage:">
        {round(capitalUsage * 100, 2)}%
      </StrategyInfoFieldValue>
    </div>
  );
};

type FieldValueProps = {
  label: string;
  children: React.ReactNode;
};
export const StrategyInfoFieldValue = ({ label, children }: FieldValueProps) => {
  return (
    <div className="flex items-center whitespace-nowrap text-sm">
      <p className="shrink-0 grow-0 basis-40 text-secondary-foreground">{label}</p>
      <p className="font-medium">{children}</p>
    </div>
  );
};
